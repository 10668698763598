// hide cookie policy
document.querySelector('.section-cookies .btn.btn-accept').addEventListener('click', (e) => {
	e.preventDefault();

	console.log('click');

	$("body").removeClass('mod-black');

	// If no validation errors
	$.ajax({
		method: "POST",
		url: "api/cookies/",
		data: {
			'cookies-accepted': 1,
		}
	}).done(function () {
		$('.section-cookies').slideToggle();
	});
});

document.querySelector('.section-cookies .btn.btn-decline').addEventListener('click', (e) => {
	e.preventDefault();

	$("body").removeClass('mod-black');

	$.ajax({
		method: "POST",
		url: "api/cookies/",
		data: {
			'cookies-accepted': 0,
		}
	}).done(function () {
		$('.section-cookies').slideToggle();
	});
});

// Pops out share modal window
$(".social-share").click(function (e) {
	// Prevents default action
	e.preventDefault();
	// Runs window popup function
	windowPopup($(this).attr("href"), 500, 300);
});

// Ajax contact form processing
$('#contact form').submit(function (e) {
	e.preventDefault();

	const spamField = document.querySelectorAll('input#Form_Email');

	// if honeypot field is filled in by spambot, do not submit
	if (spamField[0].value) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/contact/',
		data: $(this).serialize()
	}).done(function (response) {
		// If no errors
		if (response == '"success"') {
			// Reset form completely
			$('#contact form .form-element').removeClass('mod-filled');
			$('#contact form').hide().trigger('reset');

			// Show thank you message
			$('#contact .form-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#contact form .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#contact form').fadeIn();
				$('#contact .form-thanks').hide();
			}, 10000);

		} else {
			contactForm.firstElementChild.innerHTML = 'Please fix the errors below:';

			// Empty any error messages that don't need it
			document.querySelectorAll('.form-element .sub-error').forEach((element) => {
				element.innerHTML = '';
			});

			// Remove error class
			document.querySelectorAll('.form-element .form-element').forEach((element) => {
				element.classList.remove('mod-error');
			});

			var response = JSON.parse(response);

			for (const [key, value] of Object.entries(response)) {
				if (key) {
					// Implement error message to specific input that needs it
					document.querySelectorAll('.form-element .sub-error[data-error="' + key + '"]')[0].innerHTML = response[key]['message'];

					// Add error class to form element for styling
					if (key !== 'recaptcha') {
						document.querySelectorAll('.form-element.mod-' + key)[0].classList.add('mod-error');
					}
				}
			}
		}
	});
});

// Add class to inputs when clicked on add effect

if ($('form').length) {
	$('form input, form textarea').focus(function () {
		$(this).parent().addClass('mod-filled');

	}).blur(function () {
		if ($(this).val()) {
			$(this).parent().addClass('mod-filled');
		} else {
			$(this).parent().removeClass('mod-filled');
		}
	});
}

// Google Maps

// If div with ID of map exists then run the function
if ($('#map').length) {
	function createMap(el, lat, long, zoom, marker) {
		// Set a center point of the map, can be re-evaluated using `centerMap()`
		const center = { lat: lat, lng: long };

		const mapOptions = {
			zoom: zoom,
			center: center,
			fullscreenControl: false,
		};

		// Create a map instance.
		const melonMap = new MelonMap(document.getElementById(el), mapOptions);

		// Marker locations [name, lat, long, zindex]
		const markerLocation = [['Respair', lat, long, 1]];

		if (marker == true) {
			markerLocation.forEach((markerLocation) => {
				const title = markerLocation[0];

				const location = {
					lat: parseFloat(markerLocation[1]),
					lng: parseFloat(markerLocation[2])
				}

				melonMap.addMarker(location, title);
			});
		}

		// Add a map style
		melonMap.setMapStyle(
			[{ "featureType": "landscape", "stylers": [{ "saturation": -100 }, { "lightness": 60 }] }, { "featureType": "road.local", "stylers": [{ "saturation": -100 }, { "lightness": 40 }, { "visibility": "on" }] }, { "featureType": "transit", "stylers": [{ "saturation": -100 }, { "visibility": "simplified" }] }, { "featureType": "administrative.province", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "stylers": [{ "visibility": "on" }, { "lightness": 30 }] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#ef8c25" }, { "lightness": 40 }] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{ "visibility": "off" }] }, { "featureType": "poi.park", "elementType": "geometry.fill", "stylers": [{ "color": "#b6c54c" }, { "lightness": 40 }, { "saturation": -40 }] }, {}],
			{ name: 'Styled Map' }
		);
	}

	// If the contact page then create the map with the following coordiantes
	if ($('#contact').length) {
		createMap('map', 51.570316, -0.339984, 9, true);
	}
}

// Function for popout window
function windowPopup(url, width, height) {
	// Calculate the position for the popup so it's centered on the screen.
	const left = (screen.width / 2) - (width / 2);
	const top = (screen.height / 2) - (height / 2);

	// opens a window with the given url & set position from above
	window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left);
}

// Ensure the function exists on the page before we call it.
if (!!window['gdprSubscribeModal']) {
	// The form of which to attach the modal to.
	const formSelector = '#contact-form';
	// The url to link to for the privacy policy.
	const privacyPolicyURL = 'privacy-policy.html';
	// Color of the links in the modal
	const linkColour = '#000';
	// Color of the buttons in the modal.
	const buttonTextColour = '#000';

	// Call this per form.
	gdprSubscribeModal(formSelector, privacyPolicyURL, linkColour, buttonTextColour);
}

document.addEventListener('DOMContentLoaded',() => {
	/** @see assets/sass/brand.scss */
	const mq_tablet_size = 1080;
	const mq_mobile_size = 768;
	/** @see assets/sass/components/structure.scss */
	const desktopHeaderHeight = 81;
	const mobileHeaderHeight = 81;

	// Nav

	const navDirection = {
		DOWN: 'down',
		UP: 'up',
	};

	const desktopHeader = document.querySelector('.header-main.header-main-desktop');
	const mobileHeader = document.querySelector('.header-main.header-main-mobile');

	// Footer stuff.

	const policiesButton = document.querySelector('.footer-copyright-container .btn-policies-and-documents');
	const policiesContainer = document.querySelector('.footer-copyright-container .policies-and-documents-container');

	policiesButton.addEventListener('click', (event) => {
		event.preventDefault();

		policiesContainer.style.display = 'block';
		const offsetHeight = policiesContainer.offsetHeight;
		policiesContainer.style.display = '';

		const policiesButtonRect = policiesButton.getBoundingClientRect();

		if (window.outerWidth > mq_tablet_size) {
			policiesContainer.style.left = policiesButtonRect.left + 'px';
			policiesContainer.style.top = ((policiesButtonRect.top - offsetHeight) - 24) + 'px';
		} else {
			policiesContainer.style.top = ((policiesButtonRect.top - offsetHeight) - 16) + 'px';
		}

		document.querySelector('.footer-copyright-container').classList.toggle('policies-and-documents-visible');
	});

	// Header stuff.

	let previousScroll = window.scrollY || document.scrollTop;
	window.addEventListener('scroll', () => {
		// Hide footer popup.
		document.querySelector('.footer-copyright-container').classList.remove('policies-and-documents-visible');

		const currentScroll = window.scrollY || document.scrollTop || 0;

		let currentDirection;
		if (currentScroll > previousScroll) {
			currentDirection = navDirection.DOWN;
		} else if (currentScroll < previousScroll) {
			currentDirection = navDirection.UP;
		}

		previousScroll = currentScroll

		let headerHeight;
		let header;
		if (window.outerWidth <= mq_tablet_size) {
			headerHeight = mobileHeaderHeight;
			header = mobileHeader;
		} else {
			headerHeight = desktopHeaderHeight;
			header = desktopHeader;
		}

		if (header.classList.contains('hidden') && (currentScroll < headerHeight || currentDirection === navDirection.UP)) {
			header.classList.remove('hidden');
			$(header).animate({ top: 0 }, 100);
		} else if (!header.classList.contains('hidden') && (currentScroll > headerHeight && currentDirection === navDirection.DOWN)) {
			header.classList.add('hidden');
			$(header).animate({ top: -headerHeight }, 100);
		}
	});

	// Mobile burger menu

	const mobileNav = document.querySelector('.mobile-menu-popout');
	const body = document.querySelector('body');
	const mobileBurgerButton = document.querySelector('.mod-burger-btn');

	mobileBurgerButton.addEventListener('mousedown', () => {
		mobileNav.classList.add('mod-open');
		body.classList.add('mod-mobile-popout-active');
		$(mobileNav).stop().animate({ right: 0 }, 300);
	});

	document.querySelector('.mod-close-mobile-menu-btn').addEventListener('mousedown', () => {
		$(mobileNav).stop().animate({ right: "-80vw" }, 300, () => {
			mobileNav.classList.remove('mod-open');
			body.classList.remove('mod-mobile-popout-active');
		});
	});

	// Slick

	$('section.section-large-cards-carousel .list-sub-section-large-cards-carousel').slick({
		autoplay: true,
		dots: true,
		arrows: false,
	});

	$('section.section-large-cards-carousel-quotes .list-sub-section-large-cards-carousel-quotes').slick({
		autoplay: true,
		dots: true,
		arrows: false,
		autoplaySpeed: 8000
	});

	$('section.section-content-with-grid-carousel .content-with-grid-carousel-carousel').slick({
		autoplay: true,
		dots: true,
		arrows: false,
		autoplaySpeed: 6000
	});

	$('section.section-about-us-main-content .about-us-main-content-card').slick({
		adaptiveHeight: true,
		arrows: false,
		autoplay: false,
		dots: true,
		fade: true
	});


	const blogStoryHeaderImageCarousel = $('section.section-blog-story-heading .blog-story-heading-img-carousel');

	blogStoryHeaderImageCarousel.slick({
		autoplay: true,
		dots: true,
		arrows: false,
	});


	const slideCount = blogStoryHeaderImageCarousel.find('.slick-slide:not(.slick-cloned)').length;
	const dotWidth = 100 / slideCount;
	const dots = blogStoryHeaderImageCarousel.find('.slick-dots li');
	// Set with of each li so that they equally fill up the carousel's width (e.g. 3 slides would have 33% width).
	dots.css('width', dotWidth + '%');

	// Create clones that will always be visible (and grey).
	dots.find('button').each(function() {
		const clonedDot = $(this).clone();
		clonedDot.addClass('js-clone');
		$(this).parent().append(clonedDot);
	});

	// Iterate over each (green) non-clone and animate its width from 0% to 100%.
	function animateButton(i, slideCount, delay) {
		const button = dots.eq(i).find('button:not(.js-clone)');

		button.animate({ width: '100%' }, delay, () => {
			button.css('width', 0);

			blogStoryHeaderImageCarousel.slick('slickGoTo', i, false);
		});

		++i;

		if (i === slideCount) {
			i = 0;
		}

		setTimeout(() => animateButton(i, slideCount, delay), delay);
	}

	// Pass all relevant data to the function itself to keep it pure (rather than relying on global state).
	animateButton(0, slideCount, 3000);

	if (window.outerWidth >= mq_mobile_size && window.outerWidth <= mq_tablet_size) {
		$('section.section-cards-with-images .list-sub-section-cards-with-images-cards.js-slick').slick({
			// autoplay: true,
			slidesToShow: 2,
			slidesToScroll: 2,
			dots: false,
			arrows: false,
			centerMode: false,
			infinite: false
		});

		$('section.section-cards-with-list .list-sub-section-cards-with-list-cards').slick({
			// autoplay: true,
			slidesToShow: 2,
			slidesToScroll: 2,
			dots: false,
			arrows: false,
			centerMode: false,
			infinite: false
		});
	}

	// Mobile-only.
	if (window.outerWidth <= mq_mobile_size) {
		$('section.section-cards-with-images .list-sub-section-cards-with-images-cards.js-slick').slick({
			// autoplay: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: false,
			arrows: false,
			centerMode: false,
			infinite: false
		});

		$('section.section-cards-with-list .list-sub-section-cards-with-list-cards').slick({
			// autoplay: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: false,
			arrows: false,
			centerMode: false,
			infinite: false
		});
	}
});

// Client section content swap
$('#home .client-list li.content-with-grid-carousel-carousel-card').on('click touchstart', function (e) {
	e.stopPropagation();
	var content = $(this).children('.client-caption');

	$('.content-with-grid-carousel-text .main-copy').fadeOut('300', function () {
		$('.content-with-grid-carousel-text .main-copy').html(content.html()).fadeIn(300);
	});
});

/**
 * Footer terms of business dropdown
 */

$('.list-footer-terms li.mod-dropdown span').click(function (e) {
	e.preventDefault();

	$(this).next('.list-terms').toggleClass('mod-active');

});

if ($('#about-us').length) {
	// List of sentences is set in about.php at the bottom

	// Current sentence being processed
	var currentSentence = 0;

	// Character number of the current sentence being processed
	var sentenceIndex = 0;

	// Holds the handle returned from setInterval
	var interval;

	// Element that holds the text
	var elem = document.querySelector("#type-text");

	// Cursor element
	var cursor = document.querySelector("#cursor");

	// Implements typing effect
	function Type() {
		// Get substring with 1 character added
		var text = sentences[currentSentence].substring(0, sentenceIndex + 1);

		elem.style.opacity = '0';
		elem.style.transition = 'all .5s ease';

		elem.innerHTML = text;
		sentenceIndex++;

		elem.style.opacity = '1';

		// If full sentence has been displayed then start to delete the sentence after some time
		if (text === sentences[currentSentence]) {
			// Hide the cursor
			// cursor.style.display = 'none';

			clearInterval(interval);
			setTimeout(function () {
				interval = setInterval(Delete, 15);
			}, 2000);
		}
	}

	// Implements deleting effect
	function Delete() {
		// Get substring with 1 characater deleted
		var text = sentences[currentSentence].substring(0, sentenceIndex - 1);
		elem.innerHTML = text;
		sentenceIndex--;

		// If sentence has been deleted then start to display the next sentence
		if (text === '') {
			clearInterval(interval);

			// If current sentence was last then display the first one, else move to the next
			if (currentSentence == (sentences.length - 1))
				currentSentence = 0;
			else
				currentSentence++;

			sentenceIndex = 0;

			// Start to display the next sentence after some time
			setTimeout(function () {
				cursor.style.display = 'inline-block';
				interval = setInterval(Type, 100);
			}, 200);
		}
	}

	// Start the typing effect on load
	interval = setInterval(Type, 100);
}